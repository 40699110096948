<template>
    <div class="orderWrap">
        <div class="titleWrap">
            <div>
                <ul class="taskTab">
                    <li v-for="(item, index) in taskTab" :key="index" :class="nowActive == item.id ? 'on' : ''"
                        @click="changeTab(item)">
                        {{ item.name }}
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="orderData.length > 0">
            <div class="order_table" v-for="(item, index) in orderData" :key="item.id">
                <div class="order_title">
                    <div class="order_title_left">
                        订单号：{{ item.order_code }}&nbsp;&nbsp;&nbsp;&nbsp;创建时间：{{ item.order_time |
                            formatimes('yyyy-MM-dd hh:mm:ss') }}
                    </div>
                    <div class="order_title_right" v-if="item.order_status == 1">已支付</div>
                    <div class="order_title_right" v-else-if="item.order_status == 0">未支付</div>
                    <div class="order_title_right" v-else>已取消</div>
                </div>
                <div class="order_list">
                    <div class="order_list_icon">
                        <img src="../assets/images/uxb_order_icon_ke.png" v-if="item.goods_type == 1">
                        <img src="../assets/images/uxb_order_icon_vip.png" v-else>
                    </div>
                    <div class="order_list_title">
                        <p>{{ item.goods_title }}</p>
                    </div>
                    <div class="order_list_price">
                        <p v-if="item.order_status == 1">实付金额</p>
                        <p v-else>应付金额</p>
                        <strong>￥{{ item.buy_price }}</strong>
                    </div>
                    <div class="order_list_detail">
                        <p v-if="item.order_status == 0" @click="goPay(item.id)" class="pay">立即支付</p>
                        <p @click="goDetail(item.order_id)">查看详情</p>
                    </div>
                </div>

            </div>
            <div class="order_pages">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pagesize"
                    layout="total, sizes, prev, pager, next, jumper" :total="count">
                </el-pagination>
            </div>
        </div>
        <div v-else class="order_noData">
            <img src="../assets/images/uxb_order_noData.png" alt="">
            <p>暂无订单</p>
        </div>
        <form :action="submiturl" method="post" ref="pay" name="pay" style="display: none">
            <div v-for="(item, index) in postdata" :key="index">
                <input type="text" :name="index" :value="postdata[index]" />
            </div>
        </form>
        <playRemind ref="playRemind"></playRemind>
    </div>
</template>

<script>
import { getOrderList, verifyOrder, bdxtPayData } from "../assets/api";
import playRemind from '@p/learn/components/playRemind.vue'
export default {
    components:{
        playRemind
    },
    data () {
        return {
            taskTab: [
                {
                    id: 0,
                    name: '全部',
                },
                {
                    id: 1,
                    name: '已支付',
                },
                {
                    id: 2,
                    name: '待支付',
                },
                {
                    id: 4,
                    name: '已取消',
                }
            ],
            nowActive: 0,
            currentPage: 1,
            orderData: [],
            page: 1,
            pagesize: 20,
            status: 0,
            count: 0,
            submiturl: '',
            postdata: {},
            timer:null
        }
    },
    mounted () {
        this.getOder()
    },
    methods: {
        // 查看详情
        goDetail(orderid){
            this.$router.push({
                path: "/orderdetail",
                query: {
                    order_id: orderid,
                },
            })
        },
        // 去支付
        goPay (id) {
            let sync_bdxt_switch = JSON.parse(sessionStorage.getItem('schoolInfo')).sync_bdxt_switch
            if(sync_bdxt_switch==0){
                this.$refs.playRemind.openInit()
                return
            }
            let params = {
                orderId: id
            }
            verifyOrder(params).then(res => {
                if (res.code == 0) {
                    let data = {
                        orderId: id,
                        client_type: 1
                    }
                    bdxtPayData(data).then(res => {
                        if (res.code == 0) {
                            this.submiturl = res.data.submiturl
                            this.postdata = res.data.postdata
                            this.$nextTick(() => {
                                this.$refs.pay.submit();
                            });
                        }
                    })
                }
            })
        },
        getOder () {

            let params = {
                status: this.status,
                page: this.page,
                pagesize: this.pagesize
            }
            getOrderList(params).then(res => {
                console.log(res);
                if (res.code == 0) {
                    this.orderData = []
                    if (res.data.list.length > 0) {
                        this.orderData = res.data.list
                        let listFind = res.data.list.find(item=>{
                            return item.order_status == 0
                        })
                        if(listFind){
                            this.timer = setInterval(() => {
                                this.updateCountdown(listFind.order_time)
                            }, 1000);
                        }
                    }
                    this.count = res.data.count
                }
            })
        },
        // 倒计时
        updateCountdown (order_time) {
            let currentTime = parseInt(new Date().getTime() / 1000);
            let targetTime = parseInt(order_time) + 900
            let timeLeft = targetTime - currentTime;
            if (timeLeft <= 0) {
                clearInterval(this.timer);
                this.getOder()
            }
        },
        changeTab (item) {
            this.nowActive = item.id;
            this.status = item.id
            this.page = 1
            this.getOder()
        },
        handleSizeChange (val) {
            // console.log(`每页 ${val} 条`);
            this.pagesize = val
            this.getOder()

        },
        handleCurrentChange (val) {
            // console.log(`当前页: ${val}`);
            this.page = val
            this.currentPage = val
            this.getOder()
        }
    }
}
</script>

<style lang="scss" scoped>
.orderWrap {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
    color: #333;

    .order_table {
        margin: 30px 0 15px 0;
    }

    .order_title {
        display: flex;
        height: 50px;
        line-height: 50px;
        background: #F5F7FA;

        .order_title_left {
            flex: 8;
            padding-left: 20px;
        }

        .order_title_right {
            flex: 2;
            padding-right: 20px;
            text-align: right;
        }
    }

    .order_list {
        display: flex;
        height: 110px;
        border: 1px solid #EFF2F5;
        align-items: center;
        justify-content: center;
        line-height: 28px;
        font-size: 16px;
        color: #333;

        .order_list_icon {
            flex: 1;
            padding-left: 20px;
        }

        .order_list_title {
            flex: 6;

            p {
                width: 60%;
            }
        }

        .order_list_price {
            flex: 2;
            text-align: center;
            border-left: 1px solid #EFF2F5;
            border-right: 1px solid #EFF2F5;
            height: 110px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .order_list_detail {
            flex: 2;
            text-align: center;
            .pay{
                color:var(--default-color);
                display: inline;
                margin:0 5px;
            }
            p {
                cursor: pointer;
                
            }

            .pay:hover {
                color: var(--change-color);
            }
        }
    }

    .order_pages {
        display: flex;
        justify-content: end;
    }



}

.order_noData {
    text-align: center;
    margin-top: 200px;

    p {
        color: #999;
    }
}

.titleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .taskTab {
        display: flex;

        li {
            margin-right: 40px;
            font-size: 16px;
            cursor: pointer;
            position: relative;

            &.on {
                color: var(--change-color);
                font-weight: bold;
            }

            &.on::after {
                clear: both;
                content: '';
                position: absolute;
                left: 50%;
                bottom: -9px;
                width: 24px;
                height: 3px;
                margin-left: -12px;
                background: var(--change-color);
                border-radius: 1.5px;
            }
        }
    }

    i {
        font-size: 16px;
    }

    .el-button.is-round {
        //padding: 10px 20px;
        width: 118px;
        height: 36px;
        padding: 10px 23px
    }

    .bluePlainBtn {
        background: #fff;
        border: 1px solid var(--change-color);
        color: var(--change-color);

        i {}

        &:hover {
            background: var(--change-color);
            color: #fff
        }
    }
}
</style>